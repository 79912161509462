
import { IonPage, IonContent } from '@ionic/vue'
import ParagraphList from '../components/ParagraphList.vue'
import ChapterHeadline from '../components/ChapterHeadline.vue'
import { getLanguages, getSportsClubParagraphs } from '@/db'
import { computed } from 'vue'
import { settings } from '../settings'
import { getRightToLeftLanguages } from '../db'

export default {
  name: 'SportsClub',
  components: {
    IonContent,
    IonPage,
    ParagraphList,
    ChapterHeadline
  },
  setup() {
    const languages = getLanguages()
    const paragraphLists = computed(() => [
      ...languages
        .filter(
          (l) =>
            l.id === settings.value.primaryLanguage ||
            settings.value.secondaryLanguages.includes(l.id)
        )
        .map((language) => ({
          id: language.id,
          paragraphs: getSportsClubParagraphs(language.id)
        }))
    ])

    return {
      rightToLeftLanguages: getRightToLeftLanguages(),
      paragraphLists
    }
  }
}
