import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pl-10 pr-10 py-6 space-y-5 flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChapterHeadline = _resolveComponent("ChapterHeadline")!
  const _component_ParagraphList = _resolveComponent("ParagraphList")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ChapterHeadline, {
            name: 'Was ist ein Sportverein?',
            nextId: 1,
            prevId: 2
          }),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paragraphLists, (list) => {
              return (_openBlock(), _createBlock(_component_ParagraphList, {
                key: list.id,
                rtl: $setup.rightToLeftLanguages.includes(list.id),
                paragraphs: list.paragraphs
              }, null, 8, ["rtl", "paragraphs"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}